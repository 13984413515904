import {
  iosInnerHeight,
  isMobileSite,
  WIN_WIDTH,
  WIN_HEIGHT,
  MAX_MOBILE
} from "./constants";

export const checkVisible = (elm, evalType, scrT) => {
  evalType = evalType || "visible";

  var vpH = iosInnerHeight(), // Viewport Height
    y = elm.offsetTop,
    elementHeight = elm.offsetHeight;

  if (evalType === "visible") return y < vpH + scrT && y > scrT - elementHeight;
  if (evalType === "above") return y < vpH + scrT;
  if (evalType === "top") return y < scrT;
};
