import { addHandler } from "../util/scroll_resize";

export const PAGE_TRANSITION = 750;
export const CAROUSEL_TRANSITION = 300;
export const MAX_PHONE = 767;
export const MAX_MOBILE = MAX_PHONE;
export const iosInnerHeight = require("ios-inner-height");

let WIN_WIDTH = window.innerWidth;
let WIN_HEIGHT = window.innerHeight;

addHandler({
  resize: function(...args) {
    WIN_WIDTH = args[0];
    WIN_HEIGHT = args[1];
  }
});

export function isMobileSite() {
  return WIN_WIDTH <= MAX_MOBILE;
}
