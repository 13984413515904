import delegate from "delegate"
import {
  getCurrentUrl,
  getTitle,
  getBody,
  after,
  isNumber,
  isLocalLink
} from "../util/ajax_util"
import offset from "document-offset"
import { isMobileSite } from "./constants"
import { teardownContainers, initContainers } from "../util/init"

class FOSDrainageAjaxLoader {
  constructor(initContent, menu) {
    this.contentEl = document.querySelector("#content")
    this.initContent = initContent
    this.menu = menu
    this.ns = "jamiemclellan"
    this.history = window.Modernizr.history
    this.bind()

    // contentUrl tracks what's currently in #content
    this.contentUrl = window.location.pathname

    this.loading = false
  }
  toggleLoading(isLoading) {
    if (this.loading !== isLoading) {
      this.loading = isLoading
      document.documentElement.classList[this.loading ? "add" : "remove"](
        "state-loading"
      )
    }
  }
  pushState(url) {
    if (this.history) {
      // only push state if it's a new page load
      window.history.pushState({ ns: this.ns }, null, url)
    }
  }
  load(url, scrollTarget, state) {
    /* scrollTarget defaults to 0 if the url doesn't contain a # fragment. */
    const that = this
    const currentPath = getCurrentUrl().split("#")[0]
    const urlAnchor = url.split("#")[1]
    const urlPath = url.split("#")[0]
    const isHome = currentPath == "/"
    const isMobile = isMobileSite()

    const changeState = () => {
      if (!state) {
        this.pushState(url)
      }
      this.onStateChange(url)
    }

    if (urlPath == currentPath) return

    // scroll to local anchor if we're already on the page
    if (urlPath === currentPath && urlAnchor) {
      this.scrollToAnchor(urlAnchor)
      return
    }

    const transitionOut = full => {
      this.toggleLoading(true)

      Array.from(this.contentEl.children).forEach(el => {
        el.classList.add("transition-out")

        if (full) el.classList.add("transition-out-gallery")
      })

      document.documentElement
        .querySelector("header#header")
        .classList.add("transition-out")

      setTimeout(() => {
        teardownContainers(that.contentEl.children)
      }, 400)
    }

    let html

    const reveal = after(2, () => {
      const title = getTitle(html)
      const body = getBody(html)

      if (title) {
        document.title = title
      }

      body.classList.add("state-initialised")

      setTimeout(() => {
        document.body.setAttribute("class", body.getAttribute("class"))
      }, isMobile ? 1400 : 0)

      Array.from(body.querySelectorAll("#content > *")).forEach(el => {
        el.classList.add("transition-in")
      })

      const pageWrap = body.querySelector(".page-wrap")

      if (pageWrap) {
        if (isMobile) {
          const transitionWrap = document.documentElement.querySelector(
            "#transition-wrap"
          )
          transitionWrap.querySelectorAll(".image.active").forEach(el => {
            el.classList.remove("active")
          })
          let transitionEl = transitionWrap.querySelector(
            `.image.transition-${pageWrap.getAttribute("data-pattern-id")}`
          )
          if (!transitionEl)
            transitionEl = document.documentElement.querySelector(
              `#transition-wrap .image.transition-0`
            )
          transitionEl.classList.add("active")
          document.documentElement.classList.add("state-mobile-transition")

          setTimeout(() => {
            document.documentElement.classList.add("state-mobile-transition-in")

            setTimeout(() => {
              document.documentElement.classList.add(
                "state-mobile-transition-out"
              )
              document.documentElement.classList.remove(
                "state-mobile-transition-in"
              )

              setTimeout(() => {
                document.documentElement.classList.remove(
                  "state-mobile-transition",
                  "state-mobile-transition-out"
                )
              }, 1250)
            }, 2500)
            setTimeout(() => {
              transitionOut()
              this.contentEl.innerHTML = body.querySelector(
                "#content"
              ).innerHTML
              document.documentElement.querySelector(
                "#header .main-nav .title-wrap"
              ).innerHTML = body.querySelector(
                "#header .main-nav .title-wrap"
              ).innerHTML
              this.initContent(this.contentEl)
              this.contentUrl = url
              this.toggleLoading(false)

              this.menu.toggle(false)
              document.documentElement
                .querySelector("header#header")
                .classList.remove("transition-out")

              Array.from(this.contentEl.children).forEach(el => {
                el.classList.remove("transition-in")
              })
            }, 1400)
          }, 50)
        } else {
          const promises = this.menu.controller.transitionTo(
            parseInt(pageWrap.getAttribute("data-pattern-id")),
            parseInt(pageWrap.getAttribute("data-pattern-stop")),
            isHome
          )

          const transitionPage = () => {
            transitionOut()

            setTimeout(() => {
              this.contentEl.innerHTML = body.querySelector(
                "#content"
              ).innerHTML
              document.documentElement.querySelector(
                "#header .main-nav .title-wrap"
              ).innerHTML = body.querySelector(
                "#header .main-nav .title-wrap"
              ).innerHTML
              this.initContent(this.contentEl)
              this.contentUrl = url
              this.toggleLoading(false)
            }, 400)
          }

          promises[0].then(() => {
            if (!isHome) transitionPage()

            window.scroll({
              top: 0,
              behavior: "smooth"
            })
          })
          promises[1].then(() => {
            if (isHome) {
              transitionPage()
              document.documentElement.classList.add(
                "state-home-transition-out"
              )
            }

            setTimeout(() => {
              document.documentElement.classList.remove(
                "state-home-transition-out"
              )
              document.documentElement
                .querySelector("header#header")
                .classList.remove("transition-out")

              Array.from(this.contentEl.children).forEach(el => {
                el.classList.remove("transition-in")
              })

              that.menu.updateVideos(
                document.body.querySelectorAll(".carousel-item.is-video")
              )
            }, isHome ? 450 : 0)
          })
        }
      }

      // // scroll to anchor if there is one
      // if (!isNumber(scrollTarget) && urlAnchor) {
      //   this.scrollToAnchor(urlAnchor)
      // }
    })

    if (isNumber(scrollTarget)) {
      // window.scroll({
      //   top: scrollTarget
      // })
      window.scroll({
        top: scrollTarget,
        behavior: "smooth"
      })
      setTimeout(reveal, 100)
    } else {
      setTimeout(reveal, isMobile ? 100 : 0)
    }

    function doLoad(h) {
      changeState()
      html = h

      reveal()
    }

    const params = {
      method: "get",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    // if we're using nginx basic auth throw that on with our requests (for safari)
    if (window.BASIC_AUTH) {
      params.headers.Authorization = `Basic ${window.btoa(window.BASIC_AUTH)}`
    }

    return window
      .fetch(url, params)
      .then(response => {
        return response.text()
      })
      .then(doLoad)
    // .catch((err) => {
    //   console.log(err.message)
    //   window.location = url
    //   // if (xhr.status === 404) {
    //   //   doLoad(xhr.responseText)
    //   // } else {
    //   //   window.location = url
    //   // }
    // })
  }
  scrollToAnchor(target, smooth = true) {
    if (typeof target !== "number") {
      // assume a selector
      const targetEl = document.getElementById(target)
      target = targetEl.getBoundingClientRect().top + window.scrollY
    }

    window.scroll({
      top: target,
      behavior: smooth ? "smooth" : "auto"
    })
  }
  onStateChange(url) {}
  bind() {
    if (this.history) {
      window.addEventListener("popstate", e => {
        // check state to avoid handling Crapfari's pageload popstate event
        if (e.state && e.state.ns === this.ns) {
          const url = getCurrentUrl()
          this.load(url, null, e.state)
          this.onStateChange(url)
        }
      })

      // replace original state so original loaded page works
      window.history.replaceState({ ns: this.ns }, null, getCurrentUrl())
    }
  }
}

function navigation(initContent, menu) {
  const ajax = new FOSDrainageAjaxLoader(initContent, menu)

  // handle all link clicks here
  // TODO target specific links maybe?
  delegate("a", "click", e => {
    const modifier = e.shiftKey || e.ctrlKey || e.metaKey
    const link = e.delegateTarget
    const local = isLocalLink(link)

    // ignore infinite scroll next links
    if (link.matches(".next-link")) {
      return
    }

    // hard pageload if on the 404 page
    if (document.body.classList.contains("p404")) {
      return
    }

    const contentExists = document.querySelector("#content").children.length

    if (!menu.controller.locked) {
      if (!modifier && local && !menu.controller.locked) {
        ajax.load(link.getAttribute("href"))
        // menu.toggle(false)
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }
  })
}

export default navigation
