import register from "../util/init";
import { makeArray, find, findFilter, forEach } from "../tools/traversing";
import { GoulburnMap } from "./maps";
import { carousel } from "./featureCarousel";
import { goulburnVideoPlayerInit } from "./video";
import { goulburnForm } from "./form";
import { checkVisible } from "./tools";
import { isMobileSite } from "./constants";
import { toY } from "zenscroll";
import $ from "jquery";

function awardPage(container) {
  const html = document.documentElement;
  const formEl = container.querySelector('.form-wrap form')
  const linkAddEl = container.querySelector('.link-add')
  const linkTemplateEl = container.querySelector('.link-template-wrap .link-wrap')
  const MAX_LINK = linkTemplateEl ? formEl.dataset.maxLinks || 10 : 10
  let isMobile = isMobileSite();

  const getLinkCount = () => {
    return formEl ? formEl.querySelectorAll('.link-wrap').length : 0
  }

  const toggleLinkAdd = (isFocus) => {
    if (linkAddEl) {
      let isVisible = isFocus || getLinkCount() > 1

      if (!isVisible) {
        let b = false
        formEl.querySelectorAll('.link-wrap .form-field input').forEach(inputEl => {
          if (!b && inputEl.value) {
            isVisible = true
            b = true
          }
        })
      }

      linkAddEl.classList[isVisible ? 'add' : 'remove']('is-visible')
    }
  }

  const prefixUrlInput = (inputEl) => {
    inputEl.addEventListener("focusout", e => {
      let url = inputEl.value || ''

      if (url && !url.toLowerCase().startsWith('http://') && !url.toLowerCase().startsWith('https://')) {
        inputEl.setAttribute('value', 'http://' + url)
        inputEl.value = 'http://' + url
      } else if (url.toLowerCase() == 'http://' || url.toLowerCase() == 'https://') {
        inputEl.value = ''
      }

      toggleLinkAdd()
    })
  }

  if (formEl && linkAddEl) {
    const formInnerEl = formEl.querySelector('.form-inner')

    const newLink = () => {
      if (!linkTemplateEl) return

      const index = getLinkCount()
      const el = linkTemplateEl.cloneNode(true)
      formInnerEl.insertBefore(el, linkAddEl)

      Array.from(['link_website', 'link_password']).forEach(name => {
        const formFieldEl = el.querySelector('.form-field.' + name)
        const labelEl = formFieldEl.querySelector('label')
        const inputEl = formFieldEl.querySelector('input')

        labelEl.setAttribute('for', 'id_' + name + '_' + index)
        inputEl.setAttribute('name', name + '_' + index)
        inputEl.setAttribute('id', 'id_' + name + '_' + index)

        inputEl.addEventListener("focus", e => {
          toggleLinkAdd(true)
        })
        inputEl.addEventListener("focusout", e => {
          toggleLinkAdd()
        })

        if (name == 'link_website') prefixUrlInput(inputEl)
        window.ESresize()
      })

      goulburnForm(formEl)

      formEl.classList[getLinkCount() >= (MAX_LINK - 1) ? 'add' : 'remove']('has-max-links')
    }

    formEl.querySelectorAll('.link-wrap .form-field input').forEach(inputEl => {
      inputEl.addEventListener("focus", e => {
        toggleLinkAdd(true)
      })
      inputEl.addEventListener("focusout", e => {
        toggleLinkAdd()
      })
    })

    linkAddEl.addEventListener('click', e => {
      if (getLinkCount() >= MAX_LINK) return false
      newLink()
    })

    formInnerEl.querySelectorAll('input[type="url"]').forEach(prefixUrlInput)

    const workSellAtGalleryEl = formEl.querySelector('.form-wrap form .work_sell_at_gallery')
    if (workSellAtGalleryEl) {
      const workInputEls = workSellAtGalleryEl.querySelectorAll('input')
      workInputEls.forEach((workInputEl) => {
        const updateState = () => {
          if (workInputEl.value == 'yes' && workInputEl.checked) {
            workSellAtGalleryEl.classList.add('is-checked')
          } else if (workInputEl.value == 'no' && workInputEl.checked) {
            workSellAtGalleryEl.classList.remove('is-checked')
          }
        }
        workInputEl.addEventListener("change", e => {
          // e.stopPropagation()
          updateState()
        })
        updateState()
      })
    }

    formEl.querySelectorAll(".form-field.checkbox").forEach(el => {
      const inputEl = el.querySelector('input')
      const labelEl = el.querySelector('label')

      if (inputEl) {
        const updateState = () => {
          labelEl.classList[(inputEl.checked ? true : false) ? 'add' : 'remove']('is-active')
        }
        inputEl.addEventListener("change", e => {
          e.stopPropagation()
          updateState()
        })
        updateState()
      }
    })

    formEl.querySelectorAll(".form-field.where_did_you_hear_about_us li").forEach(el => {
      const inputEl = el.querySelector('input')
      const labelEl = el.querySelector('label')

      if (inputEl) {
        const updateState = () => {
          labelEl.classList[(inputEl.checked ? true : false) ? 'add' : 'remove']('is-active')
        }
        inputEl.addEventListener("change", e => {
          // e.stopPropagation()
          updateState()
        })
        updateState()
      }
    })

    formEl.querySelectorAll(".form-field.fileinput").forEach(el => {
      const inputEl = el.querySelector('input')
      const labelEl = el.querySelector('label')

      if (inputEl) {
        const updateState = (e) => {
          if (e && e.target.files[0].name) {
            inputEl.parentNode.querySelector('.file-name') && inputEl.parentNode.querySelector('.file-name').remove()
            const fileNameEl = document.createElement('div')
            fileNameEl.classList.add('file-name')
            fileNameEl.textContent = e.target.files[0].name
            inputEl.parentNode.append(fileNameEl)
          } else {
            inputEl.parentNode.querySelector('.file-name') && inputEl.parentNode.querySelector('.file-name').remove()
          }
          // labelEl.classList[(inputEl.checked ? true : false) ? 'add' : 'remove']('is-active')
        }
        inputEl.addEventListener("change", e => {
          e.stopPropagation()
          updateState(e)
        })
        updateState(false)
      }
    })
  }

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
    }
  };
}
register(".goulburn-award", awardPage);

function homePage(container) {
  const html = document.documentElement;
  let isMobile = isMobileSite();

  html.classList.add("state-home-open");

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
    }
  };
}
register(".goulburn-home", homePage);

function exhibitionsPage(container) {
  const html = document.documentElement;
  const exhibitionsListWrapEl = container.querySelector(
    ".exhibitions-list-wrap"
  );
  const headerBkgEl = html.querySelector("header#header .header-bkg");
  const sideBkg = html.querySelector("header#header .feat-exhibition");
  const featureList = container.querySelectorAll(
    ".feature-list .exhibition-header"
  );
  let currentExhibition;

  const updateHeader = () => {
    if (currentExhibition) {
      headerBkgEl.style.backgroundColor = currentExhibition.getAttribute(
        "data-menu-color"
      );
      sideBkg.style.backgroundColor = currentExhibition.getAttribute(
        "data-side-color"
      );
    }
  };

  const scrollProjects = (...args) => {
    featureList.forEach((el, i) => {
      if (checkVisible(el, "top", args[0] + headerBkgEl.offsetHeight)) {
        currentExhibition = el;
      }
    });

    updateHeader();
  };

  const resizePage = (...args) => {
    container.style.height = `${exhibitionsListWrapEl.offsetHeight}px`;
  };

  resizePage();

  return {
    resize: resizePage,
    scroll: (...args) => {
      scrollProjects(...args);
    }
  };
}
register(".goulburn-exhibitions", exhibitionsPage);

function exhibitionsArchivePage(container) { }
register(".goulburn-exhibitions-archive", exhibitionsArchivePage);

function exhibitionDetailPage(container) {
  const html = document.documentElement;
  const exhibitionWrap = container.querySelector(".exhibition-wrap");
  const exhibitionHeaderEl = container.querySelector(".exhibition-header");
  const exhibitionBodyStaticEl = container.querySelector(
    ".exhibition-body.static"
  );
  const exhibitionBodyEl = container.querySelector(".exhibition-body.fixed");
  const detailWrapEl = exhibitionBodyEl.querySelector(
    ".exhibition-detail-wrap"
  );
  const carouselWrap = exhibitionBodyEl.querySelector(
    ".exhibition-carousel-wrap"
  );
  const thumbList = container.querySelectorAll(
    ".exhibition-thumb-list .exhibition-listing"
  );
  let carouselObj;
  let isMobile = isMobileSite();
  let scrollTimer;

  if (carouselWrap) {
    const carouselInfoWrap = carouselWrap.querySelector(".carousel-info-wrap");

    carouselObj = carousel(carouselWrap);

    if (carouselInfoWrap) {
      const carouselIndexEl = carouselInfoWrap.querySelector(".carousel-index");
      const carouselCaptionList = carouselInfoWrap.querySelectorAll(
        ".caption-list .caption-listing"
      );

      if (carouselObj.flkty) {
        carouselObj.flkty.on("select", i => {
          carouselIndexEl.textContent = i + 1;

          carouselCaptionList.forEach(el => {
            el.style.display = "";
          });
          carouselCaptionList[i].style.display = "block";
        });
      }
    }
    // carouselWrap.querySelectorAll(".carousel-item.is-video").forEach(el => {
    //   const videoEl = el.querySelector(".video-player");
    //   if (videoEl) goulburnVideoPlayerInit(videoEl, {});
    // });
  }

  if (!isMobile && exhibitionWrap.classList.contains("is-feature")) {
    setTimeout(() => {
      scrollTimer = setTimeout(() => {
        if (window.scrollY < exhibitionHeaderEl.offsetHeight - 135 + 295) {
          toY(exhibitionHeaderEl.offsetHeight - 135 + 295, 1000);
        }
      }, 500);
    }, 1000);
  }

  const resizeThumbs = (...args) => {
    thumbList.forEach(el => {
      const imageEl = el.querySelector(".image-wrap");

      if (isMobile) {
        if (imageEl) imageEl.style.width = "";
      } else if (el.classList.contains("layout-center-tall")) {
        const titleWrapEl = el.querySelector(".title-wrap");
        const thumbW = el.offsetWidth;
        const thumbH = el.offsetHeight;
        const titleH = titleWrapEl.offsetHeight;
        const img = imageEl.querySelector("img");
        const imgW = parseInt(img.getAttribute("width"));
        const imgH = parseInt(img.getAttribute("height"));
        const newW = (imgW / imgH) * Math.min(thumbH - titleH, 293);

        imageEl.style.width = `${Math.min(newW, thumbW)}px`;
      }
    });
  };

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
      resizeThumbs(...args);
    },
    scroll: (...args) => {
      if (!isMobile) {
        clearTimeout(scrollTimer);
        html.classList[args[0] > 135 ? "add" : "remove"](
          "state-exhibition-scrolled"
        );
        if (exhibitionHeaderEl.offsetHeight - args[0] + 550 < 0) {
          exhibitionBodyEl.style.transform = `translateY(${exhibitionHeaderEl.offsetHeight -
            args[0] +
            550}px)`;
          exhibitionBodyEl.classList.add("active");
        } else {
          exhibitionBodyEl.style.transform = `translateY(0px)`;
          exhibitionBodyEl.classList.remove("active");
        }
      }
    }
  };
}
register(".goulburn-exhibition", exhibitionDetailPage);

function eventsPage(container) {
  const eventListEl = container.querySelector(".event-list");
  const thumbsEl = container.querySelector(".event-thumbs-list");
  const thumbsList = container.querySelectorAll(
    ".event-thumbs-list .thumb-listing"
  );
  let thumbTimer;

  const hideThumbs = () => {
    thumbsList.forEach(el => {
      el.classList.remove("active");
    });
  };

  eventListEl.querySelectorAll(".event-listing").forEach(eventEl => {
    eventEl.addEventListener("mouseenter", e => {
      clearTimeout(thumbTimer);
      hideThumbs();
      thumbsList.forEach(el => {
        if (
          parseInt(el.getAttribute("data-id")) ==
          parseInt(eventEl.getAttribute("data-id"))
        ) {
          el.classList.add("active");
        }
      });
    });
    eventEl.addEventListener("mouseleave", e => {
      thumbTimer = setTimeout(() => {
        hideThumbs();
      }, 250);
    });
  });

  const scrollSide = (...args) => {
    if (!thumbsEl) return;
    const sideH = thumbsEl.offsetTop / 3;
    let transform = 0;

    if (sideH * 2 < args[0]) {
      transform = Math.min(args[0] - sideH * 2, sideH * 2);
    }

    thumbsEl.style.transform = `translateY(-${transform}px)`;
  };

  return {
    scroll: (...args) => {
      scrollSide(...args);
    }
  };
}
register(".goulburn-events", eventsPage);

function eventDetailPage(container) {
  const html = document.documentElement;
  const headerEl = html.querySelector("header#header .header-inner");
  const detailEl = container.querySelector(".event-detail-wrap");
  const imageEl = detailEl.querySelector(".image-wrap");

  const scrollPage = (...args) => {
    const imageH = imageEl.offsetHeight;

    if (
      imageH + 500 + (container.offsetWidth / 36) * 2 >
      detailEl.offsetHeight
    ) {
      imageEl.classList.add("no-fix");
      return;
    } else {
      imageEl.classList.remove("no-fix");
    }

    if (
      args[0] +
      headerEl.offsetHeight +
      imageH +
      (container.offsetWidth / 36) * 2 <
      container.offsetHeight - 500
    ) {
      imageEl.classList.remove("bottom");
    } else {
      imageEl.classList.add("bottom");
    }
  };
  return {
    scroll: (...args) => {
      scrollPage(...args);
    }
  };
}
register(".goulburn-event", eventDetailPage);

function publicArtPage(container) {
  const html = document.documentElement;
  const headerEl = html.querySelector("header#header .header-inner");
  const pageHeaderEl = container.querySelector(".page-header");
  const pageWrapEl = container.querySelector(".alt-page-wrap");
  const detailEl = container.querySelector(".detail-wrap .text-wrap");
  const mediaEl = container.querySelector(".media-wrap");
  const imageEl = mediaEl.querySelector(".image-wrap");
  const artListEl = container.querySelector(".public-art-list");
  const mapListings = Array.from(
    container.querySelectorAll(".public-art-list .public-art-listing")
  );
  const mapEl = container.querySelector(".map");
  const mapSizerEl = mapEl.querySelector(".map-sizer");
  let isMobile = isMobileSite();
  let WIN_HEIGHT = window.innerHeight;

  const activateListing = (map, marker, idx) => {
    forEach(mapListings, listing => {
      listing.classList.remove("clicked");
    });
    mapListings[idx].classList.add("clicked");
    forEach(map.markers, m => {
      m._icon.classList.remove("clicked");
    });
    marker._icon.classList.add("clicked");
    mapSizerEl.classList.add("clicked");

    resizePage(window.innerWidth, window.innerHeight);
    const top = isMobile
      ? mapListings[idx].offsetTop +
      pageWrapEl.offsetTop +
      html.querySelector("#header").offsetHeight -
      45
      : mapListings[idx].offsetTop;
    window.scrollTo({
      top: top,
      behavior: "smooth"
    });
  };

  const map = new GoulburnMap(
    {},
    mapEl.querySelector(".map-canvas"),
    true,
    activateListing
  );
  const mapMarkers = map.markers;

  const scrollPage = (...args) => {
    const headerH = html.classList.contains("state-header-collapsed")
      ? 0
      : headerEl.offsetHeight;
    const pageHeaderH = pageHeaderEl.offsetHeight;
    const detailH = detailEl.offsetHeight;
    const imageH = imageEl.offsetHeight;
    const mapH = mapEl.offsetHeight;

    if (container.offsetHeight > pageHeaderH + headerH + imageH + mapH + 500) {
      if (args[0] >= pageHeaderH + imageH) {
        mapEl.classList.add("fixed");
      } else {
        mapEl.classList.remove("fixed");
      }

      if (args[0] + headerH + mapH < container.offsetHeight - 500) {
        mapEl.classList.remove("bottom");
      } else {
        mapEl.classList.add("bottom");
      }
    } else {
      mapEl.classList.remove("fixed", "bottom");
    }
  };

  const resizePage = (...args) => {
    WIN_HEIGHT = args[1];
    isMobile = isMobileSite();
    scrollPage(window.scrollY, 0);

    artListEl.classList.add("loading");
    let largest = 0;
    let titleH = 0;
    forEach(mapListings, listing => {
      titleH = listing.querySelector(".title-wrap").clientHeight;
      titleH += listing.querySelector(".info-wrap").clientHeight + 30;
      largest = listing.clientHeight > largest ? listing.clientHeight : largest;
    });
    artListEl.style.height = `${largest + (mapListings.length - 1) * titleH}px`;
    artListEl.classList.remove("loading");

    if (!isMobile) {
      detailEl.style.height = `${document.querySelector("#content")
        .offsetHeight -
        container.querySelector(".header-spacer").offsetHeight}px`;
    }
  };

  const listingClick = el => {
    if (el.classList.contains("clicked")) {
      el.classList.remove("clicked");
      mapSizerEl.classList.remove("clicked");
      return false;
    }
    const idx = mapListings.indexOf(el);
    const marker = mapMarkers[idx];
    map.map.flyTo(marker.getLatLng(), 18);
    activateListing(map, marker, idx);
  };

  forEach(mapListings, el => {
    const links = el.querySelectorAll(".title-wrap, .info-wrap");
    links.forEach(linkEl => {
      linkEl.addEventListener("click", e => {
        e.preventDefault();
        listingClick(el);
      });
    });
  });

  container.querySelectorAll(".video-wrap").forEach(el => {
    const videoEl = el.querySelector(".video-player");

    if (videoEl) goulburnVideoPlayerInit(videoEl, {});
  });

  return {
    resize: resizePage,
    scroll: scrollPage
  };
}
register(".goulburn-public-art", publicArtPage);

function supportPage(container) { }
register(".goulburn-support", supportPage);

function visitPage(container) { }
register(".goulburn-visit", visitPage);
