import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";

import {
  addWindowHandler,
  removeWindowHandler,
  windowSize,
  windowScroll
} from "../tools/window_events";
import { forEach } from "../tools/traversing.js";

import extend from "extend";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

/**
 * @class LeafletMap
 *
 * Creates a map using Leaflet (http://leafletjs.com/).
 */
class LeafletMap {
  /**
   * Constructs an instance of LeafletMap.
   *
   * const defaultOpts = {
   *   map: {
   *     center: (0, 0),
   *     zoom: 1,
   *     attributionControl: false,
   *     scrollWheelZoom: false
   *   },
   *   locations: [{
   *     coords: (0, 0),
   *     icon: undefined,
   *     popup: undefined,
   *     selected: false
   *   }],
   *   layers: {
   *     baseLayers: [
   *       {
   *         layer: undefined,
   *         name: ''
   *       }
   *     ],
   *     overlays: [
   *       {
   *         layer: undefined,
   *         name: ''
   *       }
   *     ]
   *   },
   *   directions: {}
   * }
   *
   * @param {Object} [opts] - Leaflet options
   * @param {Object} opts.map - Passed to Leaflet on map init
   * @param {Array} opts.locations - Locations to be added to the map as markers
   * @param {Array} opts.layers - Layers to be added to the map
   * @param {Array} opts.directions - Directions
   * @param {HtmlElement} canvas - The map element
   * @param {boolean} [runHandler] - Whether to run any window handlers on init
   * @constructs LeafletMap
   */
  constructor(opts = {}, canvas, runHandler = true) {
    // account for scenario where we didn't receive any opts
    if (!canvas) {
      canvas = opts;
      opts = {};
    }
    this.canvas = canvas;

    if (!this.canvas) {
      return;
    }

    const defaultOpts = {
      map: {
        center: (0, 0),
        zoom: 1,
        attributionControl: false,
        scrollWheelZoom: false
      },
      locations: [],
      layers: {
        baseLayers: [],
        overlays: []
      },
      routing: {}
    };

    this.options = {};
    this.options.map = extend(defaultOpts.map, opts.map);
    this.options.locations = defaultOpts.locations.concat(opts.locations);
    this.options.layers = extend(defaultOpts.layers, opts.layers);
    this.options.routing = extend(defaultOpts.map, opts.routing);

    // Create map
    this.map = L.map(canvas, this.options.map);

    // Add markers
    this.markers = [];
    forEach(this.options.locations, location => {
      const marker = L.marker(location.coords, { icon: location.icon }).addTo(
        this.map
      );

      this.markers.push(marker);

      if (location.popup) {
        marker.bindPopup(location.popup);
      }
    });

    this.markerGroup = new L.FeatureGroup(this.markers);

    // Add layers
    const baseLayers = {};
    forEach(this.options.layers.baseLayers, baseLayer => {
      baseLayer.layer.addTo(this.map);

      if (baseLayer.name) {
        baseLayers["name"] = baseLayer.name;
      }
    });
    const overlays = {};
    forEach(this.options.layers.overlays, overlay => {
      overlay.layer.addTo(this.map);

      if (overlay.name) {
        overlays["name"] = overlay.name;
      }
    });
    if (Object.keys(baseLayers).length || Object.keys(overlays).length) {
      L.control.layers(baseLayers || null, overlays || null).addTo(this.map);
    }

    // Implemented like this so that a subclass can delay these being called
    // until later if it wishes.
    if (runHandler) {
      const size = windowSize();
      const scroll = windowScroll();
      this.resizeHandler(size.width, size.height);
      this.scrollHandler(scroll.top, scroll.left);
    }
  }

  /**
   * Assigns window handler using addWindowHandler. Is abstracted into its own
   * method so that this can be overridden by subclasses, if desired.
   *
   * @method LeafletMap#assignWindowHandlers
   */
  assignWindowHandlers() {
    const that = this;
    this.handler = {
      resize: that.resizeHandler.bind(that),
      scroll: that.scrollHandler.bind(that)
    };

    addWindowHandler(this.handler, false);
  }

  /**
   * Called in the next animation frame after viewport resize is triggered.
   *
   * @method LeafletMap#resizeHandler
   * @param {int} winWidth
   * @param {int} winHeight
   */
  resizeHandler(winWidth, winHeight) {}

  /**
   * Called in the next animation frame after viewport scroll is triggered.
   *
   * @method LeafletMap#scrollHandler
   * @param {int} scrollTop
   * @param {int} scrollLeft
   */
  scrollHandler(scrollTop, scrollLeft) {}

  /**
   * Removes all handlers set any element that is not an ancestor of this.el.
   *
   * @method LeafletMap#teardown
   */
  teardown() {
    removeWindowHandler(this.handler);
  }
}

export { HtmlIcon, LeafletMap };
