import { initCarousel } from "./flickity";
import { CAROUSEL_TRANSITION } from "./constants";
import { VideoPlayer } from "../../videoplayer/videoplayer";

/**
 * Initialise a carousel with text boxes which transition separately via css,
 * triggered by the carousel
 * @param  {HTMLElement} el wrapper el
 * @return {Flickity}    flickity instance
 */
export function carousel(el, options) {
  const defaults = {};

  // NOTE initCarousel returns null if there's only one item
  const flkty = initCarousel(el, Object.assign(defaults, options));

  function onVideoPlay() {
    if (flkty) flkty.pausePlayer();
  }

  function onVideoPause() {
    if (flkty) flkty.unpausePlayer();
  }

  function onVideoEnd() {
    if (flkty) flkty.unpausePlayer();
  }

  // PB TODO: Currently there are no videos with controls inside a carousel,
  // if that becomes a thing then this will need some additional work to
  // prevent a click event on the video during a swipe of the carousel causing
  // the video to play.

  const players = {};
  Array(...el.querySelectorAll(".carousel-item")).forEach((item, i) => {
    const videoEl = item.querySelector(".video-player");
    if (videoEl) {
      // store a reference to the player under the item index, so we can use
      // the index to access it (i.e. flkty.selectedIndex)
      // subsequent videos are forced not to autoplay initially, but the
      // option is then set correctly for future use.
      players[i] = new VideoPlayer(
        {
          progressLowerColor: "#fff",
          autoplay: false,
          handlers: {
            playing: onVideoPlay,
            pause: onVideoPause,
            ended: onVideoEnd
          }
        },
        videoEl
      );
      players[i].options.autoplay =
        videoEl.getElementsByTagName("video")[0].dataset.autoplay === "true";
    }
  });

  const makeActive = (nodes, index) => {
    // transition by toggling active class, but also toggle display so that the
    // non-active elements aren't actually being rendered in the DOM

    // NOTE need to keep them rendered for mobile screens, because js measures
    // their height. Reenable display toggling on desktop if performance suffers

    Array(...nodes).forEach((node, i) => {
      if (i === index) {
        // node.style.display = 'block'
        setTimeout(() => node.classList.add("active"), 10);
      } else {
        node.classList.remove("active");
        // setTimeout(() => {
        //   node.style.display = 'none'
        // }, CAROUSEL_TRANSITION)
      }
    });
  };

  const videoActive = index => {
    Object.keys(players).forEach(key => {
      if (players[key]) {
        if (parseInt(key) === index) {
          // restart current video if it's autoplay
          if (players[key] && players[key].options.autoplay) {
            players[key].play();
          }
        } else {
          players[key].pause();
        }
      }
    });
  };

  if (flkty) {
    flkty.on("select", () => {
      Object.keys(players).forEach(key => {
        players[key].pause();
      });
      videoActive(flkty.selectedIndex);
    });
    flkty.on("settle", () => {
      Object.keys(players).forEach(key => {
        players[key].pause();
      });
    });
    flkty.on("dragStart", () => {
      document.documentElement.classList.add("state-video-freeze");
    });
    flkty.on("dragEnd", () => {
      setTimeout(() => {
        document.documentElement.classList.remove("state-video-freeze");
      }, 50);
    });
  }

  return {
    flkty: flkty,
    resize: (ww, wh) => {},
    scroll: (st, sl) => {}
  };
}
