class Accordion {
  constructor(container) {
    const that = this;
    this.container = container.querySelector(".accordion-inner");

    if (this.container) {
      const sectionListEl = this.container.querySelector(".section-list");
      this.sectionList = sectionListEl.querySelectorAll(".section-listing");

      const toggleSection = el => {
        const open = el.classList.contains("active");
        that.sectionList.forEach(sectionEl => {
          sectionEl.classList.remove("active");
        });
        el.classList[open ? "remove" : "add"]("active");
      };

      this.sectionList.forEach(el => {
        const titleEl = el.querySelector("h4");
        titleEl.addEventListener("click", () => {
          toggleSection(el);
        });
      });

      this.resize();
    }
  }

  resize() {
    this.container.classList.add("loading");
    let largest = 0;
    let titleH = 0;
    this.sectionList.forEach(el => {
      titleH = el.querySelector("h4").clientHeight;
      largest = el.clientHeight > largest ? el.clientHeight : largest;
    });
    this.container.style.height = `${largest +
      (this.sectionList.length - 1) * titleH}px`;
    this.container.classList.remove("loading");
  }
}

export { Accordion };
