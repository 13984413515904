export function pushState (url) {
  if (window.Modernizr.history) {
    window.history.pushState({ns: 'jamiemclellan'}, null, url);
  }
}

export function replaceState (url) {
  if (window.Modernizr.history) {
    window.history.replaceState({ns: 'jamiemclellan'}, null, url);
  }
}
