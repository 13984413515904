import $ from "jquery";

function createMask(el, classname) {
  var mask = el.parent("." + classname);
  if (!mask.length) {
    mask = $("<div>").addClass(classname);
    mask.insertBefore(el).append(el);
  }
  if (mask.css("position") === "static") {
    mask.css({
      position: "relative"
    });
  }
  handleFocus(el, mask);
  return mask;
}

function handleFocus(el, mask) {
  el.on("focus", function() {
    mask.addClass("focused");
  });
  el.on("blur", function() {
    mask.removeClass("focused");
  });
}

export function selectStyles(selectFields, options) {
  var CSS = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: "0",
    height: "100%",
    width: "100%",
    opacity: 0.01,
    background: "none",
    zIndex: 1,
    border: "none"
  };

  options = $.extend(
    {
      display_val: function(val, select) {
        return val;
      },
      classname: "select-mask"
    },
    options
  );

  var selects = $(selectFields);
  selects.each(function() {
    function set_val() {
      var val = select.find("option:selected").text();
      label.text(options.display_val(val, select));
    }
    var select = $(this);
    if (!select.data("select_mask_initialised")) {
      var mask = createMask(select, options.classname);
      var label = $("<span>");

      // mask.css('minWidth', select.width());
      mask.append(label);

      select.css(CSS);
      set_val();
      select.on("change", set_val);
      select.data("set_val", set_val);
      select.data("select_mask_initialised", "true");
    }

    // needed to set the width in ie
    // select[0].style.minWidth = mask.outerWidth() + 'px';
    // select[0].style.minHeight = mask.outerHeight() + 'px';
  });

  return selects;
}
