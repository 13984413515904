import $ from "jquery"; // TODO purge
import { post } from "../shoptools/fetch";
import { updateCart } from "../shoptools/index";
import { isMobileSite } from "./constants";

require("imports-loader?define=>false!../lib/jquery.form.js")($);

export function accountUpdate() {
  const html = document.documentElement;
  const cartWrap = html.querySelector(".cart-summary-wrap");

  if (cartWrap) {
    $.get(cartWrap.getAttribute("data-cart-url"), function(data) {
      if (data.cart) {
        updateCart(data.cart);
      }

      // $('body').toggleClass('age-not-verified', !data.age_verified);
    });
  }
}

export function shippingSelector(container) {
  const forms = container.querySelectorAll("form.shipping-option-selector");
  forms.forEach(form => {
    form.querySelector("select").addEventListener("change", () => {
      form.submit();
    });
  });
}

export function purchaseForm(form, layoutCallback) {
  const $form = $(form);

  $form.ajaxForm({
    beforeSubmit: function() {
      const url = $form.attr("action");
      const data = $form.serialize();

      console.log(url, data);

      // let valid = true;
      // $form.find('.option-selector select')

      post(url, data)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success) {
            form.classList.add("added");
            form.classList.remove("error");
            // document.querySelector('.cart-link').classList.add('product-added')

            if (data.cart) {
              updateCart(
                Object.assign(data.cart, { html_snippet: data.html_snippet })
              );
            }

            // client requested not to hide this
            setTimeout(() => {
              form.classList.remove("added");
            }, 5000);
          } else {
            form.querySelector(".errors").innerHTML = data.errors.join("<br>");
            form.classList.add("error");
            form.classList.remove("added");
          }
          if (layoutCallback) layoutCallback();
        });

      return false;
    }
  });
}

export function initCartMenu() {
  const html = document.documentElement;
  const cartWrap = html.querySelector(".cart-wrap");
  let isMobile = isMobileSite();

  if (cartWrap) {
    const statusEl = cartWrap.querySelector(".status-wrap");
    const cartBkgEl = cartWrap.querySelector(".cart-close-bkg");
    const cartEl = cartWrap.querySelector(".cart-snippet");
    let visible = false;
    let cartOpen = false;

    const toggleCart = toggle => {
      cartOpen = toggle;
      html.classList[cartOpen ? "add" : "remove"]("state-cart-open");

      if (html.querySelector("#content .page-header") && !isMobile) {
        let headerH = html.querySelector("#content .page-header").offsetHeight;
        if (window.scrollY < headerH) {
          window.scroll({
            top: headerH,
            left: 0,
            behavior: "smooth"
          });
        }
      }
    };
    if (statusEl) {
      statusEl.querySelector(".counter-wrap").addEventListener("click", e => {
        if (
          html.classList.contains("state-cart-has-items") &&
          !statusEl.classList.contains("link-only")
        )
          toggleCart(!cartOpen);
      });
    }
    if (cartBkgEl) {
      cartBkgEl.addEventListener("click", e => {
        toggleCart(false);
      });
    }
  }
  return {
    resize: (...args) => {
      isMobile = isMobileSite();
    }
  };
}
