import L from "leaflet";
import { LeafletMap } from "../util/mapping";
import { forEach } from "../tools/traversing.js";
import extend from "extend";

function makeIcon(idx = 0) {
  // Had to use a . here not ● or similar as it gets skewed by the
  // transform3d
  const label = idx > 0 ? idx : ".";
  return L.divIcon({
    html: label,
    iconSize: [40, 40],
    iconAnchor: [20, 20]
  });
}

class GoulburnMap extends LeafletMap {
  constructor(mapOpts = {}, canvas, runHandler = true, callback) {
    // account for scenario where we didn't receive any opts
    if (!canvas) {
      canvas = mapOpts;
      mapOpts = {};
    }

    let coordinates = canvas.dataset.coordinates || "[]";
    coordinates = coordinates.replace(/'/g, '"');
    coordinates = JSON.parse(coordinates);

    let showNumbers = canvas.dataset.showNumbers || "false";
    showNumbers = showNumbers.replace(/'/g, '"');
    showNumbers = JSON.parse(showNumbers);

    const locations = [];
    let number = 1;
    forEach(coordinates, coords => {
      locations.push({
        coords: coords.split(","),
        icon: makeIcon(showNumbers ? number : 0),
        selected: false
      });
      number++;
    });

    let tonerUrl = 'https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png';
    tonerUrl = tonerUrl.replace(/({[A-Z]})/g, function (s) {
      return s.toLowerCase();
    });

    const tonerLayer = L.tileLayer(tonerUrl, {
      subdomains: "abcd",
      minZoom: 0,
      maxZoom: 20,
      detectRetina: true
    });

    const initialZoom = parseInt(canvas.dataset.initialZoom || 0, 10);
    const defaultZoom = 17;

    const options = {
      map: {
        zoom: initialZoom || defaultZoom,
        zoomSnap: 0.5,
        zoomDelta: 0.5,
        gestureHandling: true
      },
      locations: locations,
      layers: {
        baseLayers: [
          {
            layer: tonerLayer,
            name: ""
          }
        ]
      },
      directions: {}
    };

    options.map = extend(options.map, mapOpts);

    if (canvas.dataset.interactive === "false") {
      options.map = extend(options.map, {
        dragging: false,
        tap: false,
        boxZoom: false,
        doubleClickZoom: false,
        touchZoom: false,
        minZoom: initialZoom || defaultZoom,
        maxZoom: initialZoom || defaultZoom
      });
    }

    super(options, canvas, runHandler);

    this.markerZoomLevels = canvas.dataset.zoomLevels || "[]";
    this.markerZoomLevels = this.markerZoomLevels.replace(/'/g, '"');
    this.markerZoomLevels = JSON.parse(this.markerZoomLevels);

    var that = this;
    forEach(this.markers, marker => {
      marker.on("click", function(e) {
        const idx = that.markers.indexOf(this);
        const zoom = that.markerZoomLevels[idx];
        if (zoom) {
          this._map.flyTo(this.getLatLng(), zoom);
        } else {
          this._map.flyTo(this.getLatLng());
        }
        if (callback) callback(that, marker, idx);
      });
    });

    // set map position, BUT clear call stack first as this seemingly avoids a
    // race condition in Leaflet which sometimes results in some tiles not
    // loading.
    const zoomOpts = {
      maxZoom: initialZoom || defaultZoom
    };
    if (initialZoom) {
      zoomOpts.minZoom = initialZoom;
    }
    window.setTimeout(() => {
      this.map.fitBounds(this.markerGroup.getBounds(), zoomOpts);
    }, 0);
  }
}

export { GoulburnMap };
