import { selectStyles } from "../util/jquery.formstyles";
import '@chenfengyuan/datepicker/dist/datepicker.css'
import '@chenfengyuan/datepicker/dist/datepicker.min.js'
import $ from 'jquery'

export function goulburnForm(formEl) {
  selectStyles(formEl.querySelectorAll(".form-field select"));

  formEl.querySelectorAll(".form-field.radio-select .custom-radio-select").forEach(el => {
    const inputList = el.querySelectorAll('label input')

    el.querySelectorAll('label').forEach(labelEl => {
      const inputEl = labelEl.querySelector('input')

      if (inputEl) {
        const updateState = () => {
          inputList.forEach(i => {
            i.parentNode.classList[(i.checked ? true : false) ? 'add' : 'remove']('is-active')
          })
        }
        inputEl.addEventListener("change", e => {
          e.stopPropagation()
          updateState()
        });

        updateState()
      }
    })
  })

  // Inserting a date picker
  formEl.querySelectorAll('input[name="work_completed"]').forEach(el => {
    $(el).datepicker()
    $(el).on('pick.datepicker', function (e) {
      el.parentNode.parentNode.classList.add("state-active");
    });
  })

  formEl.querySelectorAll(".form-field").forEach(el => {
    if (!el.classList.contains('form-field-initialised')) {
      const inputEl = el.querySelector("input, select, textarea");
      let hover = false;
      let state = false;

      const fieldToggle = () => {
        const inputEl = el.querySelector("input, select, textarea");

        if (inputEl) {
          state = inputEl.value ? true : false;
          const change = state || hover;

          el.classList[change ? "add" : "remove"]("state-active");
        }
      };

      el.addEventListener("focusin", e => {
        hover = true;
        fieldToggle();
      });
      el.addEventListener("focusout", e => {
        hover = false;
        fieldToggle();
      });
      if (inputEl) {
        inputEl.addEventListener("change", e => {
          e.stopPropagation();
          fieldToggle();
        });
      }

      fieldToggle();

      el.classList.add('form-field-initialised')
    }
  });
}
