import {addHandler, removeHandler} from '../util/scroll_resize'

const initRegistry = []

export default function register (selector, func) {
  /* Register an init function for elements matching the selector. */

  initRegistry.push([selector, func])
}

function getInitFunction (el) {
  /* Get a matching init function for the element from the registry, based on
     the function's paired selector. */

  for (let i = 0; i < initRegistry.length; i++) {
    if (el.matches(initRegistry[i][0])) {
      return initRegistry[i][1]
    }
  }
}

function generateGuid () {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

const handlerObjects = {}

export function initContainers (containers) {
  /* Initialises the given containers by calling the {classname}Init function
     (where classname is the container's first css class, camelcased) if it
     exists. Init functions may return a "handler" object, which may have
     the following methods:

       resize: called on window resize, see events.js
       scroll: called on window scroll, see events.js
       teardown: called when the container is removed from the DOM

     If a handler is returned, it is saved as a data attribute on the
     container.
  */

  function doInit (els, initFunc) {
    const handler = initFunc(els)
    if (handler && (handler.resize || handler.scroll)) {
      addHandler(handler, true)
    }
    return handler
  }

  Array(...containers).forEach((container) => {
    const init = getInitFunction(container)

    if (init && !container.getAttribute('data-initialised')) {
      const guid = generateGuid()
      handlerObjects[guid] = doInit(container, init)
      container.setAttribute('data-guid', guid)
      container.setAttribute('data-initialised', true)
    }
  })
}

export function teardownContainers (containers) {
  /* Called when the given containers are removed from the DOM. Removes
     scroll/resize handlers and calls the container's teardown if one exists.
  */

  Array(...containers).forEach((container) => {
    const guid = container.getAttribute('data-guid')
    const handler = handlerObjects[guid]
    // console.log('teardown', guid, container, handler)
    if (handler) {
      removeHandler(handler)
      if (handler.teardown) handler.teardown()
    }
  })
}
