import { get } from "../util/fetch";
import { pushState } from "../util/history";
import { updateLinkState } from "../util/link_state";
import { subscribeInit } from "./subscribe";
import { isMobileSite } from "./constants";

function hoursInit(el) {
  get(el.getAttribute("data-url"))
    .then(response => {
      return response.text();
    })
    .then(text => {
      if (text.length > 33) el.classList.add("long");
      el.innerHTML = text;
    });
}

const isHidden = el => {
  return el.offsetParent === null;
};

class PreviewPanel {
  constructor(wrapper, el, isMobile) {
    const that = this;
    this.html = document.documentElement;
    this.wrapperEl = wrapper;
    this.el = el;
    this.testEl = el.cloneNode(true);
    this.isMobile = isMobile;
    this.windowSize = [window.innerWidth, window.innerHeight];
    this.mainW = this.wrapperEl.offsetWidth;
    this.mainH = this.wrapperEl.offsetHeight;
    this.timer;

    // console.log(this.wrapperEl, this.mainW);

    this.wrapperEl.classList.remove("loaded");
    this.wrapperEl.appendChild(this.testEl);

    this.update(...this.windowSize);
  }

  updateTitles(textEl, size) {
    const titleList = textEl.querySelectorAll("h2");
    const dateList = textEl.querySelectorAll(".date");
    let paddingTop = 0;

    if (size > 37) {
      paddingTop = 5616 / size;
    } else {
      paddingTop = 2448 / size;
    }
    titleList.forEach(el => {
      el.style.cssText = `margin-bottom: ${0.125 *
        size}px; font-size: ${Math.max(size, 25)}px`;
    });
    dateList.forEach(el => {
      el.style.cssText = `margin-bottom: ${0.7291666667 *
        size}px; font-size: ${Math.max(size * 0.6481481481, 15)}px`;
    });
    textEl.style.paddingTop = `${paddingTop}px`;

    // &.size-1 {
    //   padding-top: 104px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 35px};
    //
    //     h2 {
    //       @include untitled(54px);
    //     }
    //     .date {
    //       @include untitled(25px);
    //       margin-bottom: 35px;
    //     }
    //   }
    // }
    // &.size-2 {
    //   padding-top: 109px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 30px};
    //
    //     h2 {
    //       @include untitled(48px);
    //       margin-bottom: 6px;
    //     }
    //     .date {
    //       @include untitled(23px);
    //       margin-bottom: 30px;
    //     }
    //   }
    // }
    // &.size-3 {
    //   padding-top: 112px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 27px};
    //
    //     h2 {
    //       @include untitled(40px);
    //       margin-bottom: 5px;
    //     }
    //     .date {
    //       @include untitled(22px);
    //       margin-bottom: 27px;
    //     }
    //   }
    // }
    // &.size-4 {
    //   padding-top: 115px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 25px};
    //
    //     h2 {
    //       @include untitled(37px);
    //       margin-bottom: 4px;
    //     }
    //     .date {
    //       @include untitled(21px);
    //       margin-bottom: 25px;
    //     }
    //   }
    // }
    // &.size-5 {
    //   padding-top: 72px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 23px};
    //
    //     h2 {
    //       @include untitled(34px);
    //       margin-bottom: 3px;
    //     }
    //     .date {
    //       @include untitled(19px);
    //       margin-bottom: 23px;
    //     }
    //   }
    // }
    // &.size-6 {
    //   padding-top: 73px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 21px};
    //
    //     h2 {
    //       @include untitled(32px);
    //       margin-bottom: 2px;
    //     }
    //     .date {
    //       @include untitled(18px);
    //       margin-bottom: 21px;
    //     }
    //   }
    // }
    // &.size-7 {
    //   padding-top: 75px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 19px};
    //
    //     h2 {
    //       @include untitled(30px);
    //       margin-bottom: 1px;
    //     }
    //     .date {
    //       @include untitled(17px);
    //       margin-bottom: 19px;
    //     }
    //   }
    // }
    // &.size-8 {
    //   padding-top: 81px;
    //
    //   .text {
    //     margin-bottom: #{$bottom-bar-height + 12px};
    //
    //     h2 {
    //       @include untitled(26px);
    //       margin-bottom: 0;
    //     }
    //     .date {
    //       @include untitled(15px);
    //       margin-bottom: 12px;
    //     }
    //   }
    // }
  }

  resizeTitle() {
    const that = this;
    if (isHidden(this.testEl)) return;
    if (this.isMobile) return;
    const targetWidth = this.mainW;
    const targetHeight = this.mainH;

    let startingSize = 10;
    this.updateTitles(this.testEl, startingSize);

    // climb in 10px increments until we get just over
    while (this.testEl.offsetHeight < targetHeight) {
      startingSize += 1;
      if (startingSize > 68) {
        startingSize = 68;
        break;
      }
      this.updateTitles(this.testEl, startingSize);
    }
    // then go back down one px at a time until we're just under
    while (this.testEl.offsetHeight >= targetHeight) {
      startingSize -= 1;
      if (startingSize <= 20) {
        startingSize = 20;
        break;
      }
      this.updateTitles(this.testEl, startingSize);
    }

    // that's our best
    this.updateTitles(this.el, startingSize);
    this.updateTitles(this.testEl, startingSize);
  }

  update(...args) {
    clearTimeout(this.timer);
    const that = this;

    this.resizeTitle();

    this.timer = setTimeout(() => {
      that.wrapperEl.classList.remove("resizing");
      that.wrapperEl.classList.add("loaded");
    }, 1000);
  }

  resize(ww, wh, force) {
    const that = this;
    this.windowSize = [ww, wh];
    this.mainW = this.wrapperEl.offsetWidth;
    this.mainH = this.wrapperEl.offsetHeight;
    this.isMobile = isMobileSite();
    this.wrapperEl.classList.add("resizing");
    that.update(that.windowSize[0], that.windowSize[1]);
  }

  destroy() {
    this.wrapperEl.classList.remove("loaded");
  }
}

export function initHome() {
  const html = document.documentElement;
  const homeEl = html.querySelector(".home-wrap");
  let isMobile = isMobileSite();

  if (!homeEl || isMobile) return null;

  const mainNavEl = homeEl.querySelector(".main-nav");
  const shapesWrapEl = homeEl.querySelector(".shapes-wrap");
  const shapesImageEl = shapesWrapEl.querySelector(".image-wrap");
  const shapeCoverEl = shapesWrapEl.querySelector(".shape-cover");
  const featuredExhibitionEl = homeEl.querySelector(".feat-exhibition");
  const hoursEl = html.querySelector(".home-wrap .hours");
  const footerEl = html.querySelector(".home-footer");
  const subscribeWrapEl = footerEl.querySelector(".overlay-subscribe-wrap");
  const subscribeButtonEl = subscribeWrapEl.querySelector("button.hamburger");
  let subscribeOpen = false;
  let isTransitioning = false;
  let lastUrl = "/";
  let scrollTopResolve;
  let sideOpenTimer;

  const TRANSITION_SPEED = 1500;

  const openSide = () => {
    clearTimeout(sideOpenTimer);
    sideOpenTimer = setTimeout(() => {
      html.classList.add("state-home-hover");
    }, 2500);
  };

  const transitionIn = () => {
    setTimeout(() => {
      html.classList.add(
        "state-home-transition-prep",
        "state-home-transition-in"
      );

      setTimeout(() => {
        html.classList.remove("state-home-transition-in");
        isTransitioning = false;
      }, TRANSITION_SPEED + 25);
    });
  };

  const transitionOut = () => {
    isTransitioning = true;
    html.classList.add("state-home-transition-out");
    setTimeout(() => {
      close();
      html.classList.remove("state-home-transition-out");
      isTransitioning = false;
      if (window.footerResize)
        window.footerResize(window.innerWidth, window.innerHeight);
    }, TRANSITION_SPEED);
  };

  const open = () => {
    if (!isTransitioning) {
      isTransitioning = true;
      window.scrollTo({ top: 0, behavior: "smooth" });

      const scrollPromise = new Promise(function (resolve, reject) {
        scrollTopResolve = resolve;
      });

      scrollPromise.then(() => {
        transitionIn();

        setTimeout(() => {
          html.classList.remove("state-home-transition-prep");

          setTimeout(() => {
            html.classList.add("state-home-open");
            html.classList.remove("state-home-hover", "state-scrollbar-active");
            openSide();
          }, 5);
        }, 20);

        lastUrl = window.location.pathname;
        pushState("/");
        updateLinkState(
          document.querySelectorAll("#header, #footer, #content")
        );
      });

      if (window.scrollY < 10 && scrollTopResolve) scrollTopResolve();
    }
  };

  const closeSubscribe = e => {
    if (e) e.preventDefault();
    subscribeOpen = false;
    html.classList.remove("state-footer-subscribe-open");
  };

  subscribeInit(subscribeWrapEl);

  const close = () => {
    if (!isTransitioning) {
      closeSubscribe();
      transitionOut();
      html.classList.remove("state-home-open");

      if (lastUrl == "/") lastUrl = window.URLS["exhibitions"];
      pushState(lastUrl);
      updateLinkState(document.querySelectorAll("#header, #footer, #content"));
    }
  };

  homeEl
    .querySelector(".home-inner > .logo-wrap ")
    .addEventListener("click", e => {
      e.preventDefault();
      close();
    });

  mainNavEl.querySelector("a.exhibitions").addEventListener("click", e => {
    e.preventDefault();
    close();
  });

  featuredExhibitionEl.addEventListener("click", e => {
    close();
  });
  footerEl.querySelector(".subscribe-link").addEventListener("click", e => {
    subscribeOpen = !subscribeOpen;
    html.classList[subscribeOpen ? "add" : "remove"](
      "state-footer-subscribe-open"
    );

    if (isMobile && subscribeOpen)
      setTimeout(() => {
        window.scroll({
          top: footerEl.parentNode.offsetTop - subscribeWrapEl.offsetHeight,
          left: 0,
          behavior: "smooth"
        });
      }, 125);
  });

  subscribeButtonEl.addEventListener("click", closeSubscribe);

  const shapeDots = 8;
  const dotRatio = 0.08424336972;
  const dotSpaceRatio = 0.04657900603;
  let currentDot = 0;
  let currentH = 0;
  let offsetH = 0;
  let hideI = 0;
  let nextI = 1;

  hoursInit(hoursEl);

  const resizeShapes = () => {
    const imageH = shapesImageEl.offsetHeight;
    const dotH = imageH * dotRatio;
    const spaceH = imageH * dotSpaceRatio;
    const wrapH = shapesWrapEl.offsetHeight - 90 - 58;

    if (wrapH < imageH) {
      if (imageH - wrapH >= hideI * dotH + Math.max(0, hideI) * spaceH) {
        hideI += 1;
      } else if (
        imageH - wrapH <
        (hideI - 1) * dotH + Math.max(0, hideI - 1) * spaceH
      ) {
        hideI -= 1;
      }
    } else {
      hideI = 0;
    }

    shapeCoverEl.style.height = `${hideI * dotH +
      Math.max(0, hideI - 1) * spaceH +
      Math.min(hideI, 1) * 1}px`;

    shapesImageEl.classList.add("loaded");
  };

  const onNowWrapEl = homeEl.querySelector(".exhibitons-on-now-outer-wrap");
  const onNowInnerEl = onNowWrapEl ? onNowWrapEl.querySelector(".exhibitons-on-now-wrap") : null;

  const previewPanelObj = onNowInnerEl ? new PreviewPanel(
    onNowInnerEl,
    onNowInnerEl.querySelector(".text")
  ) : null;

  setTimeout(() => {
    resizeShapes();
    setTimeout(() => {
      resizeShapes();
    }, 1500);
  }, 500);

  if (window.location.pathname === "/") {
    html.classList.add("state-home-open");
    openSide();
  }

  let resizeTimer;

  const reset = () => {
    clearTimeout(resizeTimer);
    html.classList.add("state-home-transition-prep");

    resizeTimer = setTimeout(() => {
      html.classList.remove("state-home-transition-prep");
    }, 20);
  };

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
      reset();
      resizeShapes();
      if (previewPanelObj) previewPanelObj.resize(...args);
    },
    scroll: (...args) => {
      if (args[0] < 10) {
        if (scrollTopResolve) {
          scrollTopResolve();
        }
      }
    },
    open: () => {
      open();
      resizeShapes();
    }
  };
}
