import Sizzle from "sizzle";
import Cookies from "js-cookie";

import { updateCart } from "../shoptools/index";

export function checkoutForm(form) {
  const toggleFormSection = (formSection, show) => {
    formSection.classList[show ? "add" : "remove"]("active");

    // need to remove required attr for hidden forms, or the browser won't let
    // it submit
    if (show) {
      // TODO
    } else {
      Sizzle("input, textarea, select", formSection).forEach(el => {
        el.required = false;
      });
    }
  };

  Sizzle(".user-form", form).forEach(userForm => {
    const userToggle = Sizzle('input[name="save_details"]', form)[0];
    const userChange = () => {
      const show = Sizzle.matchesSelector(userToggle, ":checked");
      toggleFormSection(userForm, show);
    };
    userToggle.addEventListener("change", userChange);
    userChange();
  });

  // const giftToggle = Sizzle('input[name="is_gift"]', form)[0];
  // const giftForm = Sizzle('.gift-form', form)[0];
  // const giftChange = () => {
  //   const show = Sizzle.matchesSelector(giftToggle, ':checked');
  //   toggleFormSection(giftForm, show);
  // };
  // giftToggle.addEventListener('change', giftChange);
  // giftChange();
}

export function cartInit(el) {
  const html = document.documentElement;

  const emptyCart = cart => {
    const itemListEl = el.querySelector(".checkout-item-list");
    const subtotalEl = el.querySelector(".subtotal");
    const buttonsEl = el.querySelector(".checkout-buttons");
    const closeEl = el.parentNode.querySelector(".cart-close-bkg");
    //
    if (closeEl) closeEl.click();

    if (itemListEl) itemListEl.innerHTML = "";
    if (subtotalEl) subtotalEl.innerHTML = "";
    if (buttonsEl) buttonsEl.innerHTML = "";
  };
  el.querySelectorAll(".checkout-item-list .form-row").forEach(rowEl => {
    rowEl.querySelectorAll(".remove > form").forEach(form => {
      const submit = () => {
        const url = form.getAttribute("action");
        const data = new window.FormData(form);
        const params = {
          credentials: "include",
          method: "post",
          body: data,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRFToken": Cookies.get("csrftoken")
            // 'Accept': 'application/json, text/plain, */*',
            // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        };
        window
          .fetch(url, params)
          .then(response => {
            return response.json();
          })
          .then(data => {
            updateCart(data.cart, true);
            if (data.cart.count > 0) {
              rowEl.parentNode.removeChild(rowEl);
              updateEls(el, data.html_snippet, [
                ".cart-shipping-cost",
                ".cart-subtotal",
                ".cart-total"
              ]);
            } else {
              emptyCart(data);
            }
          });
      };
      form.addEventListener("submit", e => {
        submit();
        e.preventDefault();
      });
    });
  });
  el.querySelectorAll("form.quantity, .quantity > form").forEach(form => {
    const submit = () => {
      const url = form.getAttribute("action");
      const data = new window.FormData(form);
      const params = {
        credentials: "include",
        method: "post",
        body: data,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRFToken": Cookies.get("csrftoken")
          // 'Accept': 'application/json, text/plain, */*',
          // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      };
      window
        .fetch(url, params)
        .then(response => {
          return response.json();
        })
        .then(data => {
          updateCart(data.cart, true);
          if (data.cart.count > 0) {
            updateEls(el, data.html_snippet, [
              ".line-total",
              ".cart-shipping-cost",
              ".cart-subtotal",
              ".cart-total"
            ]);
          } else {
            emptyCart(data);
          }
        });
    };
    form.addEventListener("submit", e => {
      submit();
      e.preventDefault();
    });
    form
      .querySelector('input[type="number"]')
      .addEventListener("change", submit);
    form
      .querySelector('input[type="number"]')
      .addEventListener("keyup", submit);
  });
}

function updateEls(container, html, selectors) {
  /* Selectively update container to match new content in html, based on the
     provided selectors. selectors should be a list, i.e. ['h1', '.total']
  */

  var newContent = document.createElement("div");
  newContent.innerHTML = html;

  selectors.forEach(selector => {
    container.querySelectorAll(selector).forEach((el, index) => {
      const newEl = newContent.querySelectorAll(selector)[index];

      if (newEl) {
        el.innerHTML = newEl.innerHTML;
      } else {
        el.innerHTML = "";
      }
    });
  });
}
