import { VideoPlayer } from "../../videoplayer/videoplayer";

function extendObject() {
  for (let i = 1; i < arguments.length; i++) {
    for (let key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        arguments[0][key] = arguments[i][key];
      }
    }
  }
  return arguments[0];
}

// Initialise with autoplay always set to false to ensure that non-current
// videos in the carousel don't start autoplaying. We reassign this below.
export const GoulburnVideoPlayer = VideoPlayer.bind(VideoPlayer, {
  progressLowerColor: "#fff",
  autoplay: false
});

export function goulburnVideoPlayerInit(el, opts) {
  const defaultOptions = {
    progressLowerColor: "#fff",
    autoplay: false
  };
  const options = extendObject(defaultOptions, opts);
  return new VideoPlayer(options, el);
}

const featureInit = (feature, autoplay) => {
  const videoPlayerEl = feature.querySelector(".feature-wrap .video-player");
  autoplay = !autoplay ? false : true;

  if (feature.querySelector("video").getAttribute("data-autoplay") == "true") {
    autoplay = true;
  }

  if (videoPlayerEl) {
    goulburnVideoPlayerInit(videoPlayerEl, { autoplay: autoplay });
  }
};

const videoFeaturesMobileInit = container => {
  const videos = container.querySelectorAll(".video-list-wrap .video-item");

  if (videos) {
    videos.forEach(el => {
      let videoPlayerEl = el.querySelector(".video-player");

      if (videoPlayerEl) {
        goulburnVideoPlayerInit(videoPlayerEl, { autoplay: false });
      }
    });
  }
};

export function videoFeaturesInit(container, isMobile) {
  if (isMobile) {
    videoFeaturesMobileInit(container);
  } else {
    const featureWrapEl = container.querySelector(".feature-wrap");

    const addFeature = feature => {
      const newFeature = feature.cloneNode(true);
      while (featureWrapEl.firstChild) {
        featureWrapEl.removeChild(featureWrapEl.firstChild);
      }

      featureWrapEl.appendChild(newFeature);
      featureInit(newFeature, true);
      featureWrapEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    };

    featureWrapEl.querySelectorAll(".video-item").forEach(el => {
      featureInit(el);
    });

    container
      .querySelectorAll(".content-list .video-item .video-player-wrap")
      .forEach(videoWrapEl => {
        videoWrapEl.addEventListener("click", e => {
          container
            .querySelectorAll(".content-list .video-item.current")
            .forEach(el => {
              el.classList.remove("current");
            });
          addFeature(videoWrapEl.parentNode);
          videoWrapEl.parentNode.classList.add("current");
        });
      });
  }
}
