import register from "../util/init";
import { makeArray, find, findFilter, forEach } from "../tools/traversing";
import { GoulburnMap } from "./maps";
import InfScr from "./infscr";
import { carousel } from "./featureCarousel";
import { purchaseForm } from "./cart";
import { isMobileSite } from "./constants";
import $ from "jquery";

function shopPage(container) {
  const html = document.documentElement;
  const pageHeaderEl = container.querySelector(".page-header");
  const productListWrap = container.querySelector(".product-list");
  let productList = productListWrap.querySelectorAll(".product-listing");

  const scrollState = () => {
    if (productListWrap.querySelector(".next")) {
      html.classList.add("infinite-scrolling");
    } else {
      html.classList.remove("infinite-scrolling");
    }
  };

  const infscr = InfScr($(productListWrap), {
    contentSelector: ".product-listing, .no-results",
    nextSelector: ".next",
    autoOffset: 2500,
    beforeLoad: function() {
      productListWrap.classList.add("loading-more");
    },
    onLoad: function(newContent, container) {
      productList = productListWrap.querySelectorAll(".product-listing");
      productListWrap.classList.remove("loading-more");
      scrollState();
    }
  });

  return {
    resize: (...args) => {
      infscr.resize(...args);
    },
    scroll: (...args) => {
      html.classList[args[0] >= pageHeaderEl.offsetHeight ? "add" : "remove"](
        "state-cart-fixed"
      );
      infscr.scroll(...args);
    }
  };
}
register(".goulburn-shop", shopPage);

function shopProductPage(container) {
  const html = document.documentElement;
  const pageHeaderEl = container.querySelector(".page-header");
  const carouselWrap = container.querySelector(".carousel-wrap");
  const carouselObj = carousel(carouselWrap);
  const priceText = container.querySelector(".price-wrap .price-text");
  const variantSelectEl = container.querySelector(
    ".variant-wrap .form-field select"
  );
  const thumbnailList = Array.from(
    container.querySelectorAll(".product-images-wrap .image-wrap")
  );

  if (carouselObj.flkty) {
    forEach(thumbnailList, el => {
      el.addEventListener("click", e => {
        const idx = thumbnailList.indexOf(el);
        carouselObj.flkty.select(idx);
      });
    });
  }

  if (variantSelectEl) {
    variantSelectEl.addEventListener("change", e => {
      const option = variantSelectEl.options[variantSelectEl.selectedIndex];
      priceText.textContent = option.getAttribute("data-rrp");

      container.querySelectorAll(".add-form").forEach(formEl => {
        const pkInput = formEl.querySelector('input[name="pk"]');

        if (pkInput) pkInput.setAttribute("value", option.value);
      });
    });
  }

  // Checkout init
  container.querySelectorAll(".add-form").forEach(formEl => {
    purchaseForm(formEl);
  });

  // tabsWrap.querySelectorAll(".tab").forEach((el, i) => {
  //         el.addEventListener("click", () => {
  //           const itemEl = carouselWrap.querySelector(
  //             `.carousel-item[data-id="${el.getAttribute("data-id")}"]`
  //           )
  //           carouselObj.flkty.select(getChildNumber(itemEl))
  //         })
  //       })
  html.classList.add("state-cart-fixed");
}
register(".goulburn-shop-product", shopProductPage);
