/*
  This file binds the window.resize and window.scroll events,
  reading width/height/scroll pos once and passing it on to the actual
  handlers, which should not be measuring the DOM for best performance.

  All scroll/resize event handlers should be registered via addHandler.
  A "handler" is any object with scroll and/or resize methods, i.e.

  import {addHandler} from '../util/scroll_resize.js'
  addHandler({
    resize: function(winHeight, winWidth) { ... },
    scroll: function(scrollY, scrollX) { ... }
  })
*/

import events from "add-event-listener"
import "raf/polyfill"

var handlers = []
var resizeFrameQueued = false
var scrollFrameQueued = false

function addHandler(handler, trigger) {
  handlers.push(handler)
  if (trigger) {
    queueResize()
    queueScroll()
  }
}

function windowSize() {
  var e = window
  var a = "inner"
  if (!("innerWidth" in window)) {
    a = "client"
    e = document.documentElement || document.body
  }
  return { width: e[a + "Width"], height: e[a + "Height"] }
}

function windowScroll() {
  var doc = document.documentElement
  return {
    left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
    top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  }
}

function removeHandler(handler) {
  for (var i = 0; i < handlers.length; ) {
    if (handlers[i] === handler) {
      var rest = handlers.slice(i + 1)
      handlers.length = i
      handlers.push.apply(handlers, rest)
    } else {
      // only increment if handlers is unchanged
      i++
    }
  }
}

function handleResize() {
  var wsize = windowSize()
  for (var i = 0, l = handlers.length; i < l; i++) {
    if (handlers[i].resize) handlers[i].resize(wsize.width, wsize.height)
  }
  resizeFrameQueued = false
}

function queueResize(e) {
  if (!resizeFrameQueued) {
    resizeFrameQueued = true
    window.requestAnimationFrame(handleResize)
  }
}

function handleScroll() {
  var wscroll = windowScroll()
  for (var i = 0, l = handlers.length; i < l; i++) {
    if (handlers[i].scroll) handlers[i].scroll(wscroll.top, wscroll.left)
  }
  scrollFrameQueued = false
}

function queueScroll() {
  // handle_scroll()
  if (!scrollFrameQueued) {
    scrollFrameQueued = true
    window.requestAnimationFrame(handleScroll)
  }
}

events.addEventListener(window, "resize", queueResize)
events.addEventListener(window, "scroll", queueScroll)

export { addHandler, removeHandler, windowSize, windowScroll }
