import register from "../util/init";
import { cartInit } from "../shoptools/checkout";

function cartPage(container) {
  cartInit(container.querySelector(".shoptools-wrap"));

  container.querySelectorAll(".selection-forms form").forEach(formEl => {
    formEl.querySelectorAll(".form-field select").forEach(selectEl => {
      selectEl.addEventListener("change", e => {
        formEl.submit();
      });
    });
  });
}
register(".shoptools-cart-page", cartPage);

function checkoutPage(container) {
  const html = document.documentElement;
  const billingIsShippingEl = container.querySelector(
    "#id_billing_is_shipping"
  );
  const billingWrapEl = container.querySelector(".billing-address-inner");

  const updateBilling = () => {
    if (billingIsShippingEl && billingWrapEl) {
      if (billingIsShippingEl.checked) {
        billingWrapEl.style.display = "";
      } else {
        billingWrapEl.style.display = "block";
      }
    }
  };

  if (billingIsShippingEl && billingWrapEl) {
    billingIsShippingEl.addEventListener("click", e => {
      updateBilling();
    });
  }

  updateBilling();

  html.classList.add("state-cart-fixed");
}
register(".shoptools-checkout-page", checkoutPage);
