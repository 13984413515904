
export function updateLinkState (containers, url) {
  // Add current and child-current classes to links with the container,
  // depending on the current url
  // clear all

  const links = Array(...containers).reduce(
    (arr, el) => arr.concat(Array(...el.querySelectorAll('a[href]'))), [])
  url = url || (window.location.pathname + window.location.search)

  // els.removeClass('current, child-current')
  links.forEach((el) => {
    const href = (el.getAttribute('href') || '').split('#')[0]

    if (!href) {
      // assume it's an anchor link
      return
    }
    if (href === url) {
      el.classList.add('current')
    } else {
      el.classList.remove('current')
    }

    if (url.indexOf(href) === 0 && href !== '/') {
      el.classList.add('child-current')
    } else {
      el.classList.remove('child-current')
    }
  })
}
