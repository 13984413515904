import { post } from "../util/fetch";
import $ from "jquery"; // TODO purge

export const subscribeInit = el => {
  const formWrap = el.querySelector(".subscribe-inner");
  const formEl = formWrap.querySelector("form.contact");

  if (formEl) {
    const $form = $(formEl);

    const messageEl = formWrap.querySelector(".message");

    const updateMessage = (msg, error) => {
      messageEl.textContent = msg;
      messageEl.classList[error ? "add" : "remove"]("error");
    };

    const submitForm = () => {
      const url = formEl.getAttribute("action");
      const data = $form.serialize();

      post(url, data)
        .then(response => {
          return response.json();
        })
        .then(data => {
          let error = false;
          if (data.success) {
            formWrap.classList.add("added");
            formWrap.classList.remove("error");
            formWrap.removeChild(formEl);
          } else {
            formWrap.classList.add("error");
            error = true;
          }
          updateMessage(data.message, error);
        });
    };

    $form.ajaxForm({
      beforeSubmit: function() {
        submitForm();
        return false;
      }
    });

    formEl.addEventListener("onsubmit", e => {
      e.preventDefault();
      submitForm();
      return false;
    });
  }
};
//
// if (revitWrap) {
//   container
//     .querySelector(".revit-request-link")
//     .addEventListener("click", () => {
//       revitWrap.classList.add("active");
//     });
//
//   const $revitForm = $(revitForm);
//   const revitMessageEl = revitWrap.querySelector(".message");
//
//   const updateMessage = (msg, error) => {
//     revitMessageEl.textContent = msg;
//     revitMessageEl.classList[error ? "add" : "remove"]("error");
//   };
// }
