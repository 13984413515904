export function initMenu(home) {
  const html = document.documentElement;
  const header = html.querySelector("header#header");
  const menuEl = header.querySelector(".header-inner");
  const sideBkgEl = header.querySelector(".header-side-bkg");
  const foregroundEl = sideBkgEl.querySelector(".foreground");
  const pageHeaderEl = header.querySelector(".page-header");
  const burgerWrapEl = header.querySelector(".hamburger-wrap");
  const burgerEl = burgerWrapEl.querySelector("button.hamburger");
  const subscribeWrapEl = header.querySelector(".overlay-subscribe-wrap");
  const subscribeButtonEl = subscribeWrapEl.querySelector("button.hamburger");
  let subscribeOpen = false;
  let collapsed = html.classList.contains(".state-header-collapsed");
  let oldCollapsed = collapsed;
  let slim = html.classList.contains(".state-header-slim");
  let oldSlim = slim;
  let fixed = html.classList.contains(".state-header-fixed");
  let allowFix, prevScroll, revealOnScroll, headerResizeTimer;
  let slimChange = false;
  let collapsedChange = false;
  let scrollDistance = 0;
  let menuOpen = false;
  let windowH = window.innerHeight;

  let headerTop = parseInt(header.offsetHeight);
  let collapseThreshold = 0;

  const downThreshold = 50;
  const upThreshold = 400;

  header
    .querySelector(".mobile-menu .subscribe-link")
    .addEventListener("click", e => {
      subscribeOpen = !subscribeOpen;
      html.classList[subscribeOpen ? "add" : "remove"](
        "state-mobile-subscribe-open"
      );
    });

  subscribeButtonEl.addEventListener("click", e => {
    e.preventDefault();
    subscribeOpen = false;
    html.classList.remove("state-mobile-subscribe-open");
  });

  menuEl.querySelector(".logo-wrap").addEventListener("click", e => {
    if (home) {
      e.preventDefault();
      home.open();
    }
  });

  burgerWrapEl.addEventListener("click", e => {
    e.preventDefault();
    html.classList[menuOpen ? "remove" : "add"]("state-menu-visible");
    burgerEl.classList[menuOpen ? "remove" : "add"]("is-active");
    menuOpen = !menuOpen;
  });

  // let pageHeaderOffset = (pageHeaderEl.offsetHeight + menuEl.offsetHeight) / 2;

  const setState = () => {
    html.classList[slim ? "add" : "remove"]("state-header-slim");
    html.classList[fixed ? "add" : "remove"]("state-header-fixed");
    html.classList[collapsed ? "add" : "remove"]("state-header-collapsed");

    if (slimChange) {
      slimChange = false;
      html.classList.add("state-header-resize");
      clearTimeout(headerResizeTimer);

      headerResizeTimer = setTimeout(() => {
        html.classList.remove("state-header-resize");
      }, 500);
    }
    if (collapsedChange) {
      collapsedChange = false;
      html.classList.add("state-header-resize");
      clearTimeout(headerResizeTimer);

      headerResizeTimer = setTimeout(() => {
        html.classList.remove("state-header-resize");
      }, 500);
    }
    oldSlim = slim;
    oldCollapsed = collapsed;
  };

  const setMode = () => {
    allowFix = !html.classList.contains(".no-header-fix");
    revealOnScroll = !html.classList.contains(".no-reveal-on-scroll");
  };
  setMode();

  const scrollSide = (...args) => {
    const sideH = sideBkgEl.offsetHeight;
    foregroundEl.style.transform = `translateY(-${args[0] * 0.5}px)`;
    let transform = 0;

    if (sideH < args[0]) {
      transform = args[0] - sideH;
    }

    sideBkgEl.style.transform = `translateY(-${transform}px)`;
  };

  return {
    collapse: () => {
      collapsed = true;
      setState();
    },
    setMode: setMode,
    resize: (...args) => {
      headerTop = parseInt(header.offsetHeight);
      collapseThreshold = 0;
      windowH = args[1];
      // pageHeaderOffset = (pageHeaderEl.offsetHeight + menuEl.offsetHeight) / 2;
    },
    scroll: (...args) => {
      scrollSide(...args);

      var delta = prevScroll !== undefined ? args[0] - prevScroll : 0;
      prevScroll = args[0];

      if (args[0] < 0) {
        return;
      }

      // pageHeaderEl.style.transform = `translateY(-${Math.min(
      //   args[0],
      //   pageHeaderOffset
      // )}px)`;

      // if (args[0] < pageHeaderOffset) {
      //   pageHeaderEl.style.transform = `translateY(-${args[0]}px)`;
      // } else {
      //   pageHeaderEl.style.transform = `translateY(-${pageHeaderOffset}px)`;
      // }

      // remember the cumulative distance scrolled, resetting whenever the
      // scroll direction changes
      if (scrollDistance === 0 || delta > 0 === scrollDistance > 0) {
        scrollDistance += delta;
      } else {
        scrollDistance = 0;
      }

      if (allowFix && args[0] >= headerTop) {
        fixed = true;
      } else {
        fixed = false;
      }

      // if (revealOnScroll) {
      //   if (allowFix && args[0] > collapseThreshold) {
      //     if (scrollDistance > downThreshold) {
      //       collapsed = true;
      //     } else if (scrollDistance < -upThreshold) {
      //       collapsed = false;
      //     }
      //   } else {
      //     collapsed = false;
      //   }
      // } else {
      //   collapsed = allowFix && args[0] > collapseThreshold;
      // }

      if (slim && args[0] + 190 + windowH > windowH * 2.25) {
        slim = true;
      } else if (args[0] + windowH > windowH * 2.25) {
        slim = true;
        window.ESresize();
      } else {
        slim = false;
      }

      if (
        args[0] + windowH + 400 > html.offsetHeight &&
        html.offsetHeight > windowH + 400
      ) {
        collapsed = true;
      } else {
        collapsed = false;
      }

      if (slim == !oldSlim) slimChange = true;
      if (collapsed == !oldCollapsed) collapsedChange = true;

      setState();
    }
  };
}
