// this is a wrapper to avoid using new everywhere, and to bundle the css
// could also implement site-wide defaults here

import Flickity from "flickity-fullscreen";
import "flickity/css/flickity.css";
import "flickity-fullscreen/fullscreen.css";

export function flickity(el, options) {
  const defaults = {
    dragThreshold: 30
  };
  return new Flickity(el, Object.assign(defaults, options));
}

export function flickityCounter(flkty, el, sep = "/") {
  if (!el) return;
  const updateCounter = () => {
    var slideNumber = flkty.selectedIndex + 1;
    el.innerHTML =
      "<span>" +
      slideNumber +
      "</span>" +
      sep +
      "<span>" +
      flkty.slides.length +
      "</span>";
  };
  updateCounter();
  flkty.on("select", updateCounter);
}

export function flickityTabs(flkty, tabs) {
  if (!flkty) return;

  const tabOptions = {
    dragThreshold: 10,
    selectedAttraction: 0.025,
    friction: 0.28,
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    cellAlign: "left",
    asNavFor: flkty
  };
  const tabflkty = new Flickity(tabs, tabOptions);

  const updateCurrentState = () => {
    let index = flkty.selectedIndex;
    if (tabs.querySelector(".is-selected")) {
      tabs.querySelector(".is-selected").classList.remove("is-selected");
    }
    tabs.querySelectorAll(".tab")[index].classList.add("is-selected");

    if (tabflkty) {
      tabflkty.select(index);
    }
  };

  const updateGalleryItem = el => {
    let a = Array.prototype.slice.call(tabs.querySelectorAll(".tab"));
    let index = a.indexOf(el);
    let timeout = 0;

    window.setTimeout(() => {
      flkty.select(index);
      if (tabflkty) {
        tabflkty.select(index);
      }
    }, timeout);
  };

  updateCurrentState();
  flkty.on("select", updateCurrentState);

  tabs.querySelectorAll(".tab").forEach(el => {
    el.addEventListener("click", e => {
      updateGalleryItem(el);
    });
  });
}

export function initCarousel(el, options, minCells = 2) {
  const carousel = el.querySelector(".carousel");
  const controls = el.querySelector(".carousel-controls");
  const isWrapped = "wrapAround" in options ? options.wrapAround : false;

  if (carousel.children.length < minCells) {
    return;
  }

  const flkty = flickity(
    carousel,
    Object.assign(
      {
        prevNextButtons: false,
        pageDots: true,
        // autoPlay: 1500,
        autoPlay: carousel.getAttribute("data-auto") ? 7500 : null
      },
      options
    )
  );

  if (controls) {
    flickityCounter(flkty, controls.querySelector(".counter"));

    controls.querySelector(".next").addEventListener("click", e => {
      flkty.next(true);
      e.preventDefault();
    });
    controls.querySelector(".previous").addEventListener("click", e => {
      flkty.previous();
      e.preventDefault();
    });
    controls.classList.add("active");

    flkty.on("select", () => {
      const advanced = flkty.selectedIndex > 0;
      controls.classList[advanced ? "add" : "remove"]("advanced");

      if (!isWrapped) {
        controls.classList[flkty.selectedIndex == 0 ? "add" : "remove"](
          "slides-start"
        );
        controls.classList[
          flkty.selectedIndex >= flkty.cells.length - 1 ? "add" : "remove"
        ]("slides-end");
      }
    });

    if (!isWrapped) {
      controls.classList[flkty.selectedIndex == 0 ? "add" : "remove"](
        "slides-start"
      );
    }

    flkty.on("dragStart", () => {
      carousel.classList.add("dragging");
    });
    flkty.on("dragEnd", () => {
      setTimeout(() => {
        carousel.classList.remove("dragging");
      }, 50);
    });
  }

  return flkty;
}

/**
 * initialises a carousel for an element which displays conditionally
 * @param  {HTMLElement} el   parent el, must contain a .mobile-carousel child
 * @param  {Object} options   flickity option overrides
 * @return {Object}           scrollresize handler object
 */
export function initConditionalCarousel(el, options = {}, isEnabled) {
  const carousel = el.querySelector(".mobile-carousel");
  const children = carousel.children.length;

  let flkty;
  const resize = (ww, wh) => {
    const enabled = isEnabled(children, ww);
    if (enabled && !flkty) {
      flkty = flickity(
        carousel,
        Object.assign(
          {
            prevNextButtons: false,
            pageDots: true,
            autoPlay: 4000,
            wrapAround: true
          },
          options
        )
      );
    } else if (!enabled && flkty) {
      flkty.destroy();
      flkty = null;
    }
  };

  return {
    resize: resize,
    scroll: () => {}
  };
}
