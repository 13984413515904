export function after(n, t) {
  return function() {
    return --n < 1 ? t.apply(this, arguments) : void 0
  }
}

// function is_sublink(link, possible_sublink) {
//     // Determine whether possible_sublink is a child of
//     // link in the url tree. Returns false if the links
//     // are the same.
//
//     // add trailing slashes if they're missing, to ensure
//     // that is_sublink(/test', '/test-2') returns false,
//     // but is_sublink(/test', '/test/2') returns true.
//     if (possible_sublink.slice(-1) !== '/') {
//         possible_sublink += '/'
//     }
//     if (link.slice(-1) !== '/') {
//         link += '/'
//     }
//
//     if (link !== possible_sublink && possible_sublink.indexOf(link) === 0) {
//         return true
//     }
//     else {
//         return false
//     }
// }
// NS.is_sublink = is_sublink

function getmatches(str, re) {
  /* Find and return the ith matched pattern in a regex, or
     return a blank string if not found */

  var match = str.match(re)
  if (match) {
    return match
  } else {
    return ""
  }
}

function getmatch(str, re, i) {
  /* Find and return the ith matched pattern in a regex, or
     return a blank string if not found */

  var match = str.match(re)
  if (match) {
    return match[i]
  } else {
    return ""
  }
}

export const getBody = html => {
  /* Get wrapped body element from an html document */

  var el = document.createElement("div")

  const matches = getmatches(html, /<body class="([^>]*)">([\S\s]*)<\/body>/)
  el.setAttribute("class", matches ? matches[1] : "")
  el.innerHTML = matches ? matches[2] : ""
  return el
}

export const getTitle = html => {
  /* Get title string from an html document */

  const raw = getmatch(html, /<title>([\s\S]*?)<\/title>/, 1)
  const el = document.createElement("div")
  el.innerHTML = raw
  // unescape entities
  return el.innerText
}

export function getCurrentUrl() {
  /* Get fully qualified current url. */

  return (
    window.location.pathname + window.location.search + window.location.hash
  )
}

export const isLocalLink = link => {
  /* Return true if the link points to an html page within the site. */

  const domain = getmatch(window.location.href, /[^/]+\/\/[^/]+/, 0)
  const href = link.getAttribute("href")

  if (!href) {
    return false
  }

  const normalised = href.toLowerCase()
  return (
    (normalised.startsWith(domain) || normalised.startsWith("/")) &&
    !(
      normalised.endsWith(".xml") ||
      normalised.endsWith(".pdf") ||
      normalised.endsWith(".jpg") ||
      normalised.endsWith(".jpeg") ||
      normalised.endsWith(".gif") ||
      normalised.endsWith(".png")
    )
  )
}

export function isNumber(value) {
  return typeof value === "number" && isFinite(value)
}

export const ajaxUrl = url => {
  return url + (url.match(/\?/) ? "&" : "?") + "ajax=1"
}

class AjaxLoader {
  /* Handle ajax loading and window history state within a given namespace.
     Requires Modernizr.history

  - ns: unique namespace for this loader
  - loader: function (newBody)
    function which inserts the new body content into the page, or whatever
    subset is required

  options:
  - ajaxUrl: function (url)
    converts a normal url to an ajax one. Will use standard url if not set
  - onBeforeLoad: function (url)
    called before a load is executed, return false to cancel
  - onStateChange: function ()
    called when history state changes

  methods:
  - load: function (url, scrollTarget, state)
    load a given url, scrollTarget is an optional pixel position to scroll,
    state is used internally by popstate

  */

  constructor(ns, loader, options = {}) {
    this.loader = loader
    this.ns = ns

    this.options = Object.assign(
      {
        ajaxUrl: ajaxUrl,
        history: window.Modernizr.history
      },
      options
    )

    this.bind()
  }
  load(url, scrollTarget, state) {
    /* scrollTarget defaults to 0 if the url doesn't contain a # fragment. */
    let html
    const urlAnchor = url.split("#")[1]

    if (scrollTarget === undefined && !urlAnchor) {
      scrollTarget = 0
    }

    const pushState = () => {
      if (this.options.history && !state) {
        // only push state if it's a new page load
        window.history.pushState({ ns: this.ns }, null, url)
      }
      if (this.options.onStateChange) {
        this.options.onStateChange(url)
      }
    }

    if (this.options.onBeforeLoad) {
      if (this.options.onBeforeLoad(url) === false) {
        pushState()
        return
      }
    }

    const reveal = after(2, () => {
      // var title = getTitle(html)
      // if (title) {
      //   document.title = title
      // }
      this.loader(html, url)
      if (!isNumber(scrollTarget) && urlAnchor) {
        const targetEl = document.getElementById(urlAnchor)
        window.scroll({
          top: targetEl.getBoundingClientRect().top + window.scrollY,
          behavior: "smooth"
        })
      }
    })

    if (isNumber(scrollTarget)) {
      window.scroll({
        top: scrollTarget,
        behavior: "smooth"
      })
      setTimeout(reveal, 100)
    } else {
      reveal()
    }

    function doLoad(h) {
      pushState()
      html = h
      reveal()
    }

    const params = {
      method: "get",
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    // if we're using nginx basic auth throw that on with our requests (for safari)
    if (window.BASIC_AUTH) {
      params.headers.Authorization = `Basic ${window.btoa(window.BASIC_AUTH)}`
    }

    return window
      .fetch(this.options.ajaxUrl(url), params)
      .then(response => {
        return response.text()
      })
      .then(doLoad)
    // .catch((err) => {
    //   console.log(err.message)
    //   window.location = url
    //   // if (xhr.status === 404) {
    //   //   doLoad(xhr.responseText)
    //   // } else {
    //   //   window.location = url
    //   // }
    // })
  }
  bind() {
    if (this.options.history) {
      window.addEventListener("popstate", e => {
        // check state to avoid handling Crapfari's pageload popstate event
        if (e.state && e.state.ns === this.ns) {
          var url = getCurrentUrl()
          this.load(url, null, e.state)
          if (this.options.onStateChange) {
            this.options.onStateChange(url)
          }
        }
      })

      // replace original state so original loaded page works
      window.history.replaceState({ ns: this.ns }, null, getCurrentUrl())
    }
  }
}

export default AjaxLoader
