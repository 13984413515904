import { initContainers } from "../util/init";
import { updateLinkState } from "../util/link_state";
import { addHandler } from "../util/scroll_resize.js";
import { isMobileSite } from "./constants";
import navigation from "./navigation";
import { Accordion } from "./accordions";
import { fetchData } from "../shoptools/index";
import { goulburnForm } from "./form";
import { initMenu } from "./menu";
import { initHome } from "./home";
import { initCartMenu } from "./cart";
import { cartInit } from "../shoptools/checkout";
import docReady from "doc-ready";
import { subscribeInit } from "./subscribe";

// function initMenu() {
//   const html = document.documentElement;
//   const burgerEl = html.querySelector("button.hamburger");
//   let isHome = document.body.classList.contains("home");
//   let menuVisible = false;
//
//   const toggle = (visible = null) => {
//     if (visible === null) {
//       menuVisible = !menuVisible;
//     } else {
//       menuVisible = visible;
//     }
//     document.body.classList[menuVisible ? "add" : "remove"](
//       "state-menu-visible"
//     );
//     burgerEl.classList[menuVisible ? "add" : "remove"]("is-active");
//     html.classList[menuVisible ? "add" : "remove"]("state-menu-visible");
//   };
//
//   burgerEl.addEventListener("click", e => {
//     e.preventDefault();
//     toggle();
//   });
//
//   return {
//     toggle: toggle,
//     resize: (...args) => {},
//     scroll: (...args) => {}
//   };
// }

function initFooter(container) {
  const html = document.documentElement;
  const footerEl = container.querySelector(".footer-inner");
  const subscribeWrapEl = container.querySelector(".overlay-subscribe-wrap");
  const subscribeButtonEl = subscribeWrapEl.querySelector("button.hamburger");
  let isMobile = isMobileSite();
  let subscribeOpen = false;

  footerEl.querySelector(".subscribe-link").addEventListener("click", e => {
    subscribeOpen = !subscribeOpen;
    html.classList[subscribeOpen ? "add" : "remove"](
      "state-footer-subscribe-open"
    );

    if (isMobile && subscribeOpen)
      setTimeout(() => {
        window.scroll({
          top: footerEl.parentNode.offsetTop - subscribeWrapEl.offsetHeight,
          left: 0,
          behavior: "smooth"
        });
      }, 125);
  });

  subscribeButtonEl.addEventListener("click", e => {
    e.preventDefault();
    subscribeOpen = false;
    html.classList.remove("state-footer-subscribe-open");
  });

  subscribeInit(subscribeWrapEl);

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
    }
  };
}

function initContent(container) {
  const html = document.documentElement;
  const footerEl = html.querySelector("footer#footer .footer-inner");
  const resizeList = [];
  let isMobile = isMobileSite();

  // Fill in space at the bottom of cms pages with the background colour if
  // the content is too short
  const resizeFiller = () => {
    container.querySelectorAll(".std-page-wrap, .alt-page-wrap").forEach(el => {
      const pageFillerEl = el.querySelector(".page-filler");
      const minHeight = parseInt(
        window.getComputedStyle(el, null).getPropertyValue("min-height")
      );

      if (pageFillerEl) {
        let h = 0;
        Array.from(el.children).forEach(childEl => {
          if (!childEl.classList.contains("page-filler")) {
            h += childEl.offsetHeight;
          }
        });
        if (h < minHeight) {
          pageFillerEl.style.height = `${minHeight - h}px`;
          pageFillerEl.style.display = "block";
        } else {
          pageFillerEl.style.height = "";
          pageFillerEl.style.display = "";
        }
      }
    });
  };

  html.classList.add("state-container-initialising");
  if (container) {
    // initialises newly loaded content
    const current = container.children;

    initContainers(current);

    container.querySelectorAll(".cart-wrap .cart-snippet").forEach(cartEl => {
      cartInit(cartEl);
    });

    container.querySelectorAll(".accordion-wrap").forEach(accordionEl => {
      let obj = new Accordion(accordionEl);
      resizeList.push(obj);
    });

    resizeFiller();
  }
  updateLinkState(document.querySelectorAll("#header, #footer, #content"));

  setTimeout(() => {
    html.classList.remove("state-container-initialising");
  }, 40);

  const scrollbarScreenEl = html.querySelector(".scrollbar-screen");
  const scrollbarWidthEl = html.querySelector(".scrollbar-width");
  let pageTimer, pageTimer2;

  footerEl.parentNode.style.height = `${footerEl.offsetHeight}px`;

  const resizePage = (...args) => {
    if (isMobile) return;
    clearTimeout(pageTimer);
    html.classList.add("state-footer-resizing");
    const footerH = footerEl.offsetHeight;
    let minH = args[1] - footerH;

    container.style.height = "";

    pageTimer = setTimeout(() => {
      Array.from(container.children).forEach(el => {
        minH = el.offsetHeight > minH ? el.offsetHeight : minH;
      });
      container.style.height = `${minH}px`;
      footerEl.parentNode.style.height = `${footerH}px`;
      html.classList.remove("state-footer-resizing");

      pageTimer2 = setTimeout(() => {
        let newH = 0;
        Array.from(container.children).forEach(el => {
          newH = el.offsetHeight > newH ? el.offsetHeight : newH;

          if (minH < newH) {
            html.classList.add("state-footer-resizing");
            container.style.height = `${newH}px`;
            html.classList.remove("state-footer-resizing");
          }
        });
      }, 1000);
    }, 1000);

    html.classList[
      scrollbarScreenEl.offsetWidth == scrollbarWidthEl.offsetWidth
        ? "remove"
        : "add"
    ]("state-scrollbar-active");
  };

  window.footerResize = resizePage;

  resizePage(window.innerWidth, window.innerHeight);

  let resizeTimer, scrollTimer;

  addHandler({
    resize: (...args) => {
      clearTimeout(resizeTimer);
      html.classList.add("state-resizing");
      isMobile = isMobileSite();
      resizeList.forEach(obj => {
        obj.resize();
      });
      resizeFiller();
      resizePage(...args);

      resizeTimer = setTimeout(() => {
        html.classList.remove("state-resizing");
      }, 250);
    },
    scroll: (...args) => {
      clearTimeout(scrollTimer);
      html.classList.add("state-scrolling");

      scrollTimer = setTimeout(() => {
        html.classList.remove("state-scrolling");
      }, 50);
    }
  });
}

function setup() {
  const html = document.documentElement;
  const home = initHome();
  const menu = initMenu(home);
  const footer = initFooter(html.querySelector("footer#footer"));
  const cartMenu = initCartMenu();
  // navigation(initContent, menu)

  html.querySelectorAll("form.styled, .styled-form").forEach(formEl => {
    goulburnForm(formEl);
  });

  addHandler({
    resize: (...args) => {
      menu.resize(...args);
      footer.resize(...args);
      cartMenu.resize(...args);
      if (home) home.resize(...args);
    },
    scroll: (...args) => {
      menu.scroll(...args);
      if (home) home.scroll(...args);
    }
  });
}

docReady(() => {
  setup();
  initContent(document.querySelector("#content"));
  // shoptools fetch
  fetchData();

  document.body.classList.add("state-initialised");

  window.onpopstate = function(e) {
    console.log(e.state, e.state === null);
    if (e.state === null) {
      window.location.reload();
      return false;
    }
  };
});
